.room-manager-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  padding-top: 90px;
}

.room-manager-manage-container {
  border: 1px solid #ffe1e1;
  min-width: 70%;
  max-width: 90%;
}

.logo-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  margin-top: 10px;
}

.logo-img-container img {
  width: 75px;
  height: 75px;
}

label {
  display: block !important;
  font-family: "Merriweather";
  font-size: small;
}

input[type="text"],
input[type="number"] {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  margin-bottom: 10px;
}

.view-rooms-section {
  padding: 10px;
}

.view-rooms-section h6 {
  text-align: center;
  font-family: "Merriweather";
}
