.cdrates-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 90px;
}

.cdrates-manage-container {
  border: 1px solid #ffe1e1;
  min-width: 70%;
  max-width: 90%;
}

.logo-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

.logo-img-container img {
  width: 75px;
  height: 75px;
}

label {
  display: block !important;
  font-family: "Merriweather";
  font-size: small;
}

input[type="text"],
input[type="number"] {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  margin-bottom: 10px;
}

.loader-container {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.response-messages-container {
  width: 100%;
  height: 24px;
  text-align: center;
}

.view-cdrates-section {
  padding: 10px;
}

.view-cdrates-section th {
  text-align: center;
}

.view-cdrates-section td {
  text-align: end;
}

.view-cdrates-section h6 {
  text-align: center;
  font-family: "Merriweather";
}

.cd-rates-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: 100%;
}

.cd-rates-buttons button {
  padding: 5px 10px;
  margin-left: 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #860505;
  color: white;
  outline: none;
  font-family: "Merriweather";
  font-size: small;
}

.cd-rates-buttons button:hover {
  background-color: #470303;
}
