.forgot-password-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 90px;
}

.forgot-password-main-container {
  border: 1px solid #ffe1e1;
  width: 75%;
  max-width: 600px;
  padding: 20px;
}

.forgot-password-loader-container {
  width: 100%;
}

.forgot-password-main-container button {
  padding: 10px 20px;
  border: 1px solid transparent;
  width: 100%;
  background-color: #860505;
  color: white;
  outline: none;
  font-family: "Merriweather";
  border: none;
  border-radius: 4px;
}

.forgot-password-main-container button:hover {
  background-color: #470303;
}

.response-message-container {
  width: 100%;
  height: 24px;
  text-align: center;
}

.forgot-pasword-choice-container {
  display: flex;
  flex-direction: column;
}

.forgot-password-choice-buttons {
  text-align: center;
}

.forgot-password-choice-buttons #forgot-password-choice-button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #ffffff;
  border-bottom: 3px solid transparent;
  border-radius: 0px;
  font-family: "Merriweather";
  color: black;
  font-size: small;
  width: auto;
}

.forgot-password-choice-buttons #forgot-password-choice-button.active {
  border-bottom: 3px solid #470303;
}

.react-international-phone-input-container {
  display: flex;
  margin-bottom: 10px;
}
