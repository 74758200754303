.login-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 90px;
}

.login-register-container {
  border: 1px solid #ffe1e1;
  width: 75%;
  max-width: 600px;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.toggle-buttons button {
  padding: 10px 20px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  background-color: #fff7f7;
  border-bottom: 3px solid transparent;
  font-family: "Merriweather";
  font-size: small;
}

.toggle-buttons button.active {
  background-color: #fddfdf;
  border-bottom: 3px solid #470303;
}

.logo-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

.logo-img-container img {
  width: 75px;
  height: 75px;
}

.login-register-choice-container {
  text-align: center;
  font-family: "Merriweather";
}

.login-choice-buttons #login-choice-button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #ffffff;
  border-bottom: 3px solid transparent;
  border-radius: 0px;
  font-family: "Merriweather";
  color: black;
  font-size: small;
}

.login-choice-buttons #login-choice-button.active {
  border-bottom: 3px solid #470303;
}

.login-form,
.register-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.login-form h2,
.register-form h2,
.register-form h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "Merriweather";
}

.form-group {
  margin-bottom: 15px;
}

.login-form button[type="button"],
.register-form button[type="button"] {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #860505;
  color: white;
  cursor: pointer;
  outline: none;
  font-family: "Merriweather";
  margin-bottom: 10px;
}

.login-form button[type="button"]:hover,
.register-form button[type="button"]:hover {
  background-color: #470303;
}

label {
  display: block !important;
  font-family: "Merriweather";
  font-size: small;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

input[disabled] {
  cursor: not-allowed;
}

.response-message-container {
  width: 100%;
  height: 24px;
  text-align: center;
}

#btn-custom-primary {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #860505;
  color: white;
  cursor: pointer;
  outline: none;
  font-family: "Merriweather";
  float: right;
}

#btn-custom-primary:hover {
  background-color: #470303;
}

#btn-custom-primary:disabled {
  cursor: not-allowed;
  background-color: #777777;
}

#btn-custom-secondary {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #edbaba;
  color: black;
  cursor: pointer;
  outline: none;
  font-family: "Merriweather";
  margin-right: 10px;
}

#btn-custom-secondary:hover {
  background-color: #e17d7d;
}

.confirm-user-div {
  width: 100%;
}

.password-div label {
  margin-top: 10px;
}

.registeration-attributes-div label {
  margin-top: 10px;
}
